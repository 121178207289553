@font-face {
    font-family: vampireWars;
    src: url(./../fonts/VampireWars.ttf);
}

@font-face {
    font-family: conthrax;
    src: url(./../fonts/conthrax-sb.ttf);
}

@font-face {
    font-family: 'Conthrax Hv';
    src: url('./../fonts/ConthraxHv-Regular.woff2') format('woff2'),
        url('./../fonts/ConthraxHv-Regular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

$bg: #020305;
$primary: #F5850C;
$primary-opacity: rgba(245, 133, 12, 0.2);
$light: #F4F4F4;
$gray: #747681;
$light-gray: rgba(217, 217, 217, 0.08);
$white: #FFFFFF;
$purple: #9747FF;
$green: #05EF63;

$primary-font: 'Poppins', sans-serif;
$secondary-font: 'conthrax', sans-serif;
$heading-font: 'conthrax', sans-serif;
$title-font: 'Conthrax Hv';