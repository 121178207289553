.modal-w-550 {
    max-width: 550px !important;
}
.modal-w-650 {
    max-width: 650px !important;
}

.modal {
    .modal-content {
        background: url(https://assets-cdn.virtua.com/images/play/jetpack/partnerBg.png) $bg;
        background-repeat: no-repeat;
        background-size: 175% 100%;
        background-position: center;
        box-shadow: 0px 0px 20px rgba(245, 133, 12, 0.5);
        border-radius: 15px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }

    .close-modal {
        position: absolute;
        right: 10px;
        top: 10px;
        background: transparent !important;
        border: none !important;
    }

    .modal-body {
        padding: 80px 20px;

        .sectionText {
            max-width: 80%;
            margin: 0 auto;
            text-align: center;

            @media (max-width: 767px) {
                max-width: 95%;
            }
            &-title {
                font-size: clamp(1.2rem, 2vw + 0.75rem, 2rem);
                line-height: clamp(24px, 2vw + 16px, 36px);
                margin-bottom: 20px;
            }

            .custom-btn {
                margin-top: 20px;
                min-width: 238px;

                @media (max-width: 767px) {
                    min-width: 180px;
                    font-size: 16px !important;
                    margin-top: 10px;
                }
            }
        }
    }

    &.playModal {
        .sectionText {
            max-width: 100%;

            &-title {
                font-size: clamp(1rem, 2vw + 0.75rem, 1.15rem);
                line-height: clamp(20px, 2vw + 16px, 30px);
                margin-bottom: 20px;
            }

            &-content {
                max-width: 65%;
                margin: 0 auto;

                @media (max-width: 767px) {
                    max-width: 90%;
                }
            }
        }
    }
}

.modal-backdrop.show {
    opacity: .45 !important;
}
