@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

.scrollbar-vgn::-webkit-scrollbar,
.scrollbar-vgn .claimModal::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-vgn::-webkit-scrollbar-thumb,
.scrollbar-vgn .claimModal::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px #9247ff, inset -2px -2px 2px #9247ff;
}

.scrollbar-vgn::-webkit-scrollbar-track,
.scrollbar-vgn .claimModal::-webkit-scrollbar-track {
  background: linear-gradient(90deg, 111, 111 1px, #111 0, #111);
}

.overflow-container {
  overflow: hidden;
  padding-right: 8px;
}

#wrapper {
  width: 100%;
  position: relative;
  /*overflow: hidden;*/
}

#main {
  width: 100%;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.w-100 {
  width: 100%;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: block;
}

.list-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 15px;
}

.container.fluid {
  width: 100%;
  max-width: none;
}

.container:after {
  display: block;
  clear: both;
  content: '';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.word-break {
  word-break: break-word;
}

.d-block {
  display: block;
}

.gaming-main-area img {
  vertical-align: top;
  border-style: none;
  height: 100%;
  width: 100%;
}

.gaming-main-area video.overlay-default-img {
  height: 100%;
  position: relative;
  width: 100%;
  object-fit: cover;
}

.main-video-holder {
  height: 100%;
  position: relative;
  width: 100%;
}

.main-video-holder:before {
  position: absolute;
  content: '';
  background: url("../../assets/images/bg.png") no-repeat left center;
  opacity: 0.86;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 2;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

textarea.form-control {
  height: auto;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

button:focus,
textarea:focus,
input:focus,
select {
  outline: none !important;
}

input:focus {
  border: 1px solid hsla(0, 0%, 100%, .16) !important;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.d-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.gaming-right-side div:has(.jetpack-banner-style) {
  position: sticky;
  top: 0;
}

.max-100 {
  max-width: 100% !important;
}

.rounded {
  border-radius: 100%;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.purple {
  color: #9247ff;
}

.fs-24 {
  font-size: 24px;
}

.fw-600 {
  font-weight: 600;
}

.flex-text-right {
  justify-content: flex-end;
}

.flex-text-center {
  justify-content: center;
}

.column-direction {
  flex-direction: column;
}

.gap-20 {
  gap: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.p-0 {
  padding: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0 !important;
}

.pointer {
  cursor: pointer;
}

/* header */
#header {
  padding: 20px 15px;
  border-bottom: 1px solid rgba(146, 71, 255, 0.4);
  background: rgba(12, 3, 26, 0.8);
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 40px;
  width: 100%;
}

.gaming-main-area {
  margin-top: 30px;
}

.gaming-right-side {
  width: 365px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gaming-right-side button {
  width: 141px;
  border-radius: 16px;
}

.gaming-right-side .text {
  bottom: 0;
  top: inherit;
  width: 100%;
  transform: translate(-50%, -40px);
}

.gaming-right-side img.banner-img {
  border-radius: 16px;
  height: 640px;
}

.gaming-images-holder {
  height: 640px;
  overflow-y: auto;
  width: 266px;
  overflow-x: hidden;
}

.gaming-images-holder::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: none;
}

.gaming-images-holder:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.50);
}

.gaming-images-holder::-webkit-scrollbar {
  width: 6px;
}

.gaming-images-holder a.game-item {
  width: 200px;
  height: 200px;
}

.gaming-images-holder img {
  border-radius: 16px;
  object-fit: cover;
}

.gaming-play:before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
}

.gaming-play .holder-img {
  height: 640px;
}

.play-btn-container img {
  border-radius: 25px;
  width: 170px;
  height: 170px;
  object-fit: cover;
  margin: 0 0 20px;
}

.loader-icon {
  border-radius: 25px;
  width: 170px;
  height: 170px;
  margin: 0 0 20px;
  position: absolute;
  top: 10px;
  background-color: #222;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #000e, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 0.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.play-btn-container h2,
.play-btn-container h3 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px;
}

.text p {
  margin: 0 0 30px;
}

.text h3 {
  max-width: 200px;
  margin: 0 auto 15px;
  line-height: 44px;
  font-size: 38px;
  font-weight: 700;
}

.gaming-play .text button {
  min-width: 170px;
  width: 100%;
}

.gaming-play .text button svg {
  margin-left: 8px;
  max-width: 20px;
}

.gaming-main-area .custom-btn {
  color: #fff;
  padding: 15px 30px;
  font-family: 'poppins', sans-serif !important;
}

.gaming-main-area .custom-btn:hover {
  opacity: 0.9;
}

.gaming-main-area .custom-btn.game-play-btn.purple {
  position: absolute;
  bottom: 6px;
  left: 50%;
  width: 83%;
  transform: translate(-50%, 0);
  height: 44px;
  padding: 0;
  z-index: 10;
  background: rgba(146, 71, 255, 0.75) !important;
}

.gaming-main-area .custom-btn.purple {
  background: #9247ff !important;
  border-radius: 16px;
}

.gaming-main-area .custom-btn.rounded {
  border-radius: 100px !important;
}

.gaming-main-area .custom-btn.lg {
  height: 56px;
  line-height: 56px;
}

.gaming-main-area .custom-btn.md {
  height: 44px;
  line-height: 44px;
}

.gaming-main-area .custom-btn svg {
  margin-left: 10px;
}

.gaming-main-area .custom-btn.play-now {
  font-size: 16px !important;
}

.gaming-play:has(.overlay-default-img)::before {
  display: none;
}

.gaming-description {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px 0;
  border-radius: 16px;
  background: linear-gradient(90deg, rgba(146, 71, 255, 0.1) 0%, rgba(71, 84, 103, 0.1) 100%);
  backdrop-filter: blur(5px);
}

.gaming-description .head-area {
  padding-inline: 40px;
  border-bottom: 1.5px solid rgba(217, 217, 217, 0.1);
  margin-bottom: 40px;
}

.gaming-description .bottom-area {
  padding-inline: 40px;
}

.login-btn {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), #9247ff !important;
  width: 124px;
  color: #fff;
  height: 50px;
  border: 1.5px solid #9247ff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 12px;
  font-size: 14px;
  padding: 0 20px;
  text-transform: capitalize;
  transition: all 0.25s;
  font-family: 'poppins', sans-serif !important;
}

.icon-img {
  margin-right: 10px;
}

.gaming-description h3 {
  margin: 0 0 41px;
}

.gaming-description h3.title {
  margin: 0 0 30px;
}

.gaming-description ul {
  gap: 24px;
  /*margin: 0 0 61px;*/
  padding: 0 0 61px;
}

.gaming-description ul.feature {
  padding-left: 18px;
  gap: 12px;
}

.gaming-description p {
  margin: 0 0 60px;
  line-height: 22px;
}

.gaming-description ul li span {
  width: 220px;
  display: inline-block;
}

.feature-holder {
  margin: 0 !important;
}

.truncate-xp {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.feature-holder li span {
  font-size: 18px;
  font-weight: 400;
}

.gaming-metaverse {
  height: 720px;
  margin-top: 20px;
}

.gaming-metaverse img {
  object-fit: cover;
  border-radius: 16px;
  object-position: left center;
}

.gaming-metaverse .text {
  top: 68%;
  left: inherit;
  transform: translate(34px, -50%);
}

.games-footer {
  background: #0c031a;
  padding: 80px 0 40px;
  position: relative;
  margin-top: 40px;
}

.games-footer .footer-content {
  max-width: 1620px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.games-footer .footer-content .footer {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.games-footer .footer-content .footer .footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.games-footer .footer-content .footer .footer-left .footer-left-inner {
  display: flex;
}

.games-footer .footer-content .footer .footer-left .footer-left-inner .footer-col {
  flex: 1 1;
}

.games-footer strong {
  margin: 0 0 20px;
  font-size: 22px;
  display: inline-block;
}

.games-footer .footer-content .footer .footer-left .footer-left-inner ul li {
  margin: 0 0 16px;
}

.games-footer li a {
  color: #fff;
}

.games-footer .footer-content .footer .footer-left img {
  max-width: 48px;
}

.games-footer .footer-content .footer .footer-left span.title {
  font-size: 14px;
  margin-left: 10px;
  max-width: 780px;
  opacity: 0.7;
}

.games-footer .footer-content .footer .footer-right {
  width: 30%;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner {
  display: flex;
  flex-direction: column;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .right-inner-content {
  margin-bottom: 65px;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .input-holder {
  position: relative;
}

.input-holder-form {
  margin: 0 0 30px;
}

.input-holder-form .spinner-border,
.gaming-main-area .spinner-border {
  border: 0.2em solid #9247ff !important;
  border-right-color: transparent !important;
}

.input-holder input {
  display: block;
  width: 100% !important;
  height: 54px;
  padding-left: 0.95rem;
  padding-right: 0.95rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: rgb(163, 163, 163) !important;
  background: padding-box transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.16);
  border-image: initial;
  border-radius: 100px !important;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn {
  align-items: center;
  background: #f9f9f9;
  border-radius: 50px;
  color: #000;
  border: none;
  cursor: pointer !important;
  display: flex;
  font-size: 12px;
  height: 45px;
  justify-content: center;
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 4px;
  width: 75px;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .input-holder .input-holder-btn img {
  width: auto;
  height: auto;
}

.footer-social-icons {
  display: flex;
  justify-content: space-evenly;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .footer-social-icons li:first-child {
  margin-left: 0;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .footer-social-icons li:last-child {
  margin-right: 0;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .footer-social-icons li {
  margin-left: 15px;
  margin-right: 15px;
}

.games-footer .footer-social-icons li a {
  background: rgba(217, 217, 217, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.games-footer .footer-social-icons li:hover img {
  opacity: 1;
}

.cgoodp {
  width: 20px;
  max-height: 20px;
  opacity: 0.4;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons {
  display: flex;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons a:first-child {
  margin-right: 15px;
}

.btn-footer .icon {
  margin-right: 15px;
}

.games-footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text {
  display: none;
}

.btn-footer {
  align-items: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  max-width: 180px;
  padding: 15px;
  text-transform: none;
  transition: all 0.3s ease;
  width: 100%;
}

.games-footer li a:hover {
  color: #9247ff !important;
}

.games-footer:after {
  background: hsla(0, 0%, 100%, 0.1);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* .user-guidelines {
  background: #0c031a;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
} */

.user-guidelines li {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.user-guidelines li a:hover {
  color: #9247ff !important;
}

.user-guidelines li:first-child {
  margin-left: 0;
}

.user-guidelines li a {
  color: #fff;
}

/*.game-modal {*/
/*    top: 0;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    background: rgba(0, 0, 0, 0.94);*/
/*    width: 100%;*/
/*    overflow-x: hidden;*/
/*    overflow-y: auto;*/
/*    z-index: 400;*/
/*}*/

.game-modal .modal-holder {
  min-height: calc(100% - 3.5rem);
  /*max-width: 600px;*/
  /*width: 100%;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.game-modal .modal-content-game {
  background: rgba(12, 3, 26, 0.94);
  border: 1px solid rgba(146, 71, 255, 0.32);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  border-radius: 20px;
  min-height: auto;
}

.game-modal .inner-content {
  position: relative;
  padding: 60px 30px 50px;
  z-index: 10;
  text-align: center;
  color: #fff;
}

.high-boxes {
  margin-bottom: 40px;
  gap: 36px;
}

.high-score-box {
  width: 160px;
  height: 160px;
  border: 1px solid #9247FF;
  border-radius: 100px;
  padding: 20px;
}

.game-modal .inner-content .high-boxes .high-score-box img {
  width: 80%;
  height: 100%;
  margin: 0;
  object-fit: contain;
}

.game-modal .inner-content .high-boxes>.high-score-box.one img {
  width: 55%;
}

.game-modal .inner-content .modal-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  /*text-transform: uppercase;*/
  margin: 0 0 40px;
}

.game-modal-game .inner-content .modal-title {
  max-width: 440px;
  margin: 0 auto;
}

.game-modal .inner-content img {
  width: 200px;
  height: 200px;
  margin: 0 0 20px;
}

.game-modal-game .inner-content img {
  width: 320px;
  height: 320px;
  display: block;
  margin: 0 auto 20px;
}

.game-modal .inner-content p {
  font-size: 20px;
  line-height: 26px;
  /*max-width: 450px;*/
  margin: 0 0 38px;
  font-weight: 400;
  color: #c9c9c9;
}

.claimModal .modal-content {
  background: none !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
  border: none !important;
}

.inner-content button.claim-btn {
  width: 250px;
  background: #9247ff;
  text-transform: capitalize;
  border-radius: 100px;
  color: #fff;
  font-size: 18px !important;
  font-weight: 500;
  height: 50px;
  border: none;
  font-family: 'poppins', sans-serif !important;
}

.inner-content button.claim-btn:hover {
  opacity: 0.8;
}

.cross-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
}

.navbar-nav-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: auto;
}

.update-nav {
  border-left: 1.5px solid #1d1b31;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  padding-inline: 30px;
  position: relative;
}

.update-nav .season-level {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  min-width: 180px;
}

.update-nav .season-level .season-list {
  align-items: center;
  display: flex;
  gap: 5px;
}

.update-nav .season-level .season-list h5 {
  color: #c9b0ff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 20px;
  margin: 0;
}

.update-nav .season-level .season-list img {
  width: 20px;
}

.update-nav .season-level .season-list span {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.update-nav .season-level .level-list {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

.update-nav .season-level .level-list p {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
}

.update-nav .season-level .level-list p span {
  color: #aaa;
}

.update-nav .progress-bar {
  background: #2d1b54;
  border-radius: 100px;
  height: 8px;
  width: 100%;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

.update-nav .progress-bar .path {
  background: linear-gradient(272.69deg, #9247ff -13.44%, #f7f7f7 132.63%);
  border-radius: 50px;
  display: inline-block;
  height: 100%;
  width: 0%;
}

.update-nav.user-dropdown-nav {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding-right: 0;
}

.update-nav .user-profile,
.update-nav .user-profile .user-profile-text {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
}

.update-nav .user-profile .user-profile-img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.update-nav .user-profile .user-profile-img img {
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.update-nav .user-profile .user-profile-text {
  gap: 0;
}

.update-nav .user-profile .user-profile-text .user-profile-text-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.update-nav .user-profile .user-profile-text p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.update-nav .user-profile .user-profile-text p span {
  display: inline;
  font-size: 14px;
  vertical-align: middle;
}

.update-nav .user-profile .user-profile-text p img {
  margin-inline: 5px;
  width: 20px;
  height: 20px;
}

.update-nav .user-profile .user-profile-text p span {
  display: inline;
  font-size: 14px;
  vertical-align: middle;
}

.update-nav .user-profile .user-profile-text .user-profile-text-right {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.update-nav .user-profile .user-profile-text .user-profile-text-right .arrow img {
  width: 12px;
}

.update-nav .user-profile-dropdown {
  background: #0c031a;
  border: 1.5px solid #241c30;
  border-radius: 24px;
  min-width: 330px;
  padding: 24px;
  position: absolute;
  right: 0;
  top: 80px;
  z-index: 20;
}

.update-nav .user-profile-dropdown .user-info .user-img {
  border-radius: 50%;
  height: 72px;
  margin-inline: auto;
  width: 72px;
}

.update-nav .user-profile-dropdown .user-info .user-img img {
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.user-profile-dropdown .user-info .user-name h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-inline: auto;
  max-width: 182px;
  overflow: hidden;
  padding-top: 5px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-profile-dropdown .user-info .user-name h5 {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  text-align: center;
}

.user-profile-dropdown .user-info .user-name p {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  justify-content: center;
  margin: 7px 0 0;
  opacity: 74%;
  text-align: center;
}

.user-profile-dropdown .user-info .user-name .global-mob {
  display: none;
}

.user-profile-dropdown .user-info .user-name p span {
  color: #9247ff;
  font-size: 18px;
  font-weight: 600;
  opacity: 100%;
}

.user-profile-dropdown .user-info ul {
  align-items: center;
  -webkit-column-gap: 18px;
  column-gap: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-block: 20px !important;
  margin-inline: 0;
  padding: 0;
  row-gap: 15px;
  height: 100%;
}

.user-profile-dropdown .user-info ul li {
  height: 44px;
  width: 44px;
}

.user-profile-dropdown .user-info ul li img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.user-profile-dropdown .user-logout {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  transition: 0.3s linear;
  width: max-content;
}

.user-profile-dropdown .user-logout p {
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.user-profile-dropdown .user-logout:hover p {
  color: #fff;
}

.game-play-container {
  position: relative;
  height: 640px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
}

.game-play-container p.text-center {
  max-width: 720px;
}

.loading_game-play-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.overlay-game-img {
  object-fit: cover;
}

.play-btn-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.play-btn-container h1 {
  font-size: 60px;
  font-weight: 600;
}

.user-profile-dropdown .user-logout:hover svg path {
  fill: #fff;
}

.jetpack-banner-style img {
  height: 720px !important;
}

.game-play-container iframe.iframe-height {
  height: 640px !important;
}

.banner-img {
  object-fit: cover;
}

.manage-account-btn {
  border-radius: 100px;
  height: 40px !important;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  color: #9247FF;
  border: 1.5px solid #9247FF;
  width: 100%;
  font-weight: 500;
  font-family: 'poppins', sans-serif !important;
}

.manage-account-btn:hover {
  background: #9247FF;
  color: #fff;
}

.modal-close-icon {
  position: absolute;
  z-index: 5;
  right: 0;
  margin: 20px;
  cursor: pointer;
}

.internetError {
  z-index: 2000;
  position: absolute;
  background-color: #000;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-game-frame {
  border-radius: 0;
  width: 100%;
  height: 100%;
  /*margin: 20px 0 20px;*/
  position: absolute;
  top: 0;
  background-color: #111;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #000e, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 0.9s infinite;
  z-index: 0;
}

@media (max-width: 1550px) {
  .game-play-container {
    height: 540px;
  }

  .game-play-container iframe.iframe-height {
    height: 540px !important;
  }

  .games-footer .footer-content .footer .footer-left {
    width: 67%;
  }

  .games-footer .footer-content .footer .footer-right {
    width: 33%;
  }

  .gaming-metaverse {
    height: 40vw;
  }

  .jetpack-banner-style img {
    height: 40vw !important;
  }

  .gaming-metaverse .text {
    top: 70%;
    transform: translate(20px, -50%);
  }

  .gaming-images-holder {
    height: 540px;
    width: 220px;
  }

  .gaming-images-holder a.game-item {
    width: 166px;
    height: 166px;
  }

  .gaming-right-side img.banner-img {
    height: 540px;
  }
}

@media (max-width: 1420px) {
  .play-btn-container h1 {
    font-size: 44px;
  }
}

@media (max-width: 1280px) {
  .games-footer .footer-content .footer .footer-right {
    width: 40%;
  }

  .gaming-metaverse .text {
    top: 72%;
  }

  .gap-20 {
    gap: 14px;
  }

  .gaming-images-holder {
    height: 520px;
  }

  .game-play-container {
    height: 520px;
  }

  .gaming-right-side img.banner-img {
    height: 520px;
  }

  .game-play-container iframe.iframe-height {
    height: 520px !important;
  }

  .gaming-play .holder-img {
    height: 520px;
  }

  .gaming-images-holder a.game-item {
    width: 163px;
    height: 162px;
  }

  .game-play-container {
    margin-left: -5px;
  }
}

@media (max-width: 1180px) {
  .gaming-main-area {
    flex-wrap: wrap;
  }

  .flex-1 {
    flex-basis: 100%;
    width: 100%;
  }

  .gaming-metaverse {
    height: 720px;
  }

  .jetpack-banner-style img {
    height: 720px !important;
  }

  .gaming-right-side {
    width: 100%;
    flex-direction: row;
  }

  .gaming-right-side .col-width {
    width: 50%;
  }

  .gaming-right-side img.banner-img {
    height: 720px;
  }

  .gaming-right-side div:has(.jetpack-banner-style) {
    position: inherit;
    top: inherit;
  }
}

@media(max-width: 992px) {
  .games-footer .footer-content .footer {
    flex-direction: column-reverse;
    gap: 0;
  }

  .games-footer .footer-content .footer .footer-right {
    width: 100%;
    text-align: center;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .input-holder {
    margin: 0 auto 30px;
    max-width: 400px;
  }

  .footer-social-icons {
    justify-content: center;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .footer-social-icons li {
    margin-left: 7.5px;
    margin-right: 7.5px;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 20px;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .mobile-footer-text .title {
    padding-bottom: 18px;
    padding-top: 18px;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons {
    justify-content: center;
  }

  .games-footer .footer-content .footer .footer-left {
    width: 100%;
    margin-top: 40px;
  }

  .web-footer-text {
    display: none;
  }

  .btn-footer .icon {
    margin-right: 10px;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .footer-buttons a:first-child {
    margin-right: 10px;
  }

  .btn-footer {
    font-size: 13px;
    max-width: 135px;
    padding: 12px;
  }

  .games-footer .footer-content .footer .footer-right .footer-right-inner .right-inner-content {
    margin-bottom: 25px;
  }

  .games-footer strong {
    margin: 0 0 10px;
    font-size: 16px;
  }

  .games-footer .footer-content .footer .footer-left .footer-left-inner ul li {
    margin: 0 0 5px;
    font-size: 12px;
  }

  .user-guidelines li {
    font-size: 12px;
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
}

@media(max-width: 767px) {
  .user-profile-dropdown .user-info .user-name .global-mob {
    display: block;
  }

  .game-play-container {
    margin-left: 0;
  }

  .gaming-images-holder a.game-item img {
    width: 120px;
  }

  .update-nav .user-profile .user-profile-text .user-profile-text-left {
    display: none;
  }

  .update-nav .user-profile .user-profile-text .user-profile-text-right {
    height: 10px;
    width: 10px;
  }

  .update-nav.user-dropdown-nav {
    padding-left: 8px;
  }

  .update-nav {
    padding-inline: 8px;
  }

  .update-nav .season-level .season-list span {
    font-size: 12px;
  }

  .update-nav .season-level {
    gap: 4px;
  }

  .game-modal .inner-content .modal-title {
    font-size: 36px;
    line-height: 40px;
  }

  .gaming-left-side>.flex.gap-20 {
    flex-wrap: wrap;
  }

  .gaming-images-holder {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    height: auto;
    display: inline-block !important;
    /*margin-bottom: 14px;*/
    text-align: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .gaming-images-holder a.game-item {
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 14px;
  }

  .gaming-images-holder a.game-item:last-child {
    margin-right: 0;
  }

  .play-btn-container h1 {
    font-size: 30px;
  }

  .play-btn-container svg.svg-logo {
    width: 100px;
    height: 100px;
  }

  .game-play-container {
    height: 430px;
  }

  .game-play-container iframe.iframe-height {
    height: 430px !important;
  }

  .gaming-metaverse {
    height: 640px;
  }

  .gaming-metaverse .text {
    top: inherit;
    bottom: 0;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .gaming-right-side img.banner-img {
    height: 680px;
  }

  .gaming-main-area .custom-btn.play-now {
    min-width: 170px;
  }

  .play-btn-container .mob-mode {
    display: none;
  }

  .play-btn-container {
    width: 100%;
  }

  .gaming-description .bottom-area {
    padding-inline: 21px;
  }

  .gaming-description .head-area {
    padding-inline: 20px;
    margin-bottom: 30px;
  }

  .gaming-description ul li {
    font-size: 14px;
  }

  .gaming-description ul li span {
    width: 120px;
  }

  .gaming-description p {
    font-size: 14px;
  }

  .gaming-right-side {
    flex-direction: column;
  }

  .gaming-right-side>.col-width {
    width: 100%;
  }

  .gaming-main-area .custom-btn.game-play-btn.purple {
    font-size: 12px !important;
    height: 34px;
    min-width: 100px;
  }

  .gaming-main-area .custom-btn.game-play-btn.purple svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
}

@media(max-width: 650px) {

  /*.claimModal .modal-dialog{*/
  /*  margin: 0.5rem !important;*/
  /*}*/
  .high-score-box {
    width: 110px;
    height: 110px;
  }

  .high-boxes {
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 30px;
  }

  .game-modal .inner-content p {
    font-size: 16px;
  }

  .game-modal .inner-content {
    padding: 60px 16px 30px;
  }
}

@media(max-width: 420px) {
  .gaming-metaverse img {
    object-fit: inherit;
  }

  .gaming-metaverse .text {
    width: 100%;
  }

  .gaming-images-holder {
    justify-content: inherit;
  }

  .gaming-images-holder a.game-item {
    flex: 1;
  }

  .play-btn-container h1 {
    font-size: 20px;
  }

  .play-btn-container p {
    font-size: 12px;
  }

  .play-btn-container svg.svg-logo {
    width: 55px;
    height: 55px;
  }

  .gaming-description ul li {
    font-size: 12px;
  }
}

@media(max-width: 380px) {
  .gaming-description ul li {
    font-size: 12px;
  }
}

/* footer css */
.footer-bootom-list {
  background: #0c031a;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.footer-bootom-list .container {
  max-width: 1680px;
  margin: 0px auto;
  padding: 0px 40px;
  position: relative;
}

.footer-bootom-list .bottom-wrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
}

.powered-text {
  font-size: 14px;
  gap: 5px;
  max-width: 195px;
}

@media(max-width: 600px) {
  .footer-bootom-list .container {
    padding: 0px 15px;
  }
}

@media (max-width: 767px) {
  .footer-bootom-list .bottom-wrap {
    gap: 20px;
  }
}

@media (max-width: 992px) {
  .footer-bootom-list .bottom-wrap {
    flex-direction: column;
  }
}