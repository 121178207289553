@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./assets/sass/global.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  margin: 0;
  color: $white !important;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font: 400 16px/24px $primary-font !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg !important;
  letter-spacing: -0.03em;
}

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px $primary, inset -2px -2px 2px $primary;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg,
      rgba(17, 17, 17, 1),
      rgba(17, 17, 17, 1) 1px,
      rgba(17, 17, 17, 1) 0,
      rgba(17, 17, 17, 1));
}