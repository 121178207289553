.loader-config-container {
    z-index: 1000000;
    min-height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.loader {
    width: 120px;
    height: 120px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    background: #9247FF;
    cursor: none;
    margin-inline: auto;

    img {
        max-width: 60%;
        max-height: 60%;
    }
}