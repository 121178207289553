.feedback-page {
  position: relative;
  max-width: 1480px;
  margin: 80px auto 0;
  padding: 0 15px;
  @media (max-width: 900px) {
    margin: 25px auto 0;
    padding: 0 20px;
  }

  .feedback-success {
    border-radius: 12px;
    border: 1px solid #9247ff;
    background: rgba(51, 22, 92, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 18px;
    width: max-content;
    font-size: 16px;
    position: fixed;
    top: 20px;
    right: -100%;
    z-index: 200;
    transition: all .5s linear;
    &.active{
    right: 20px;
    }
  }

  h2 {
    color: #b785ff;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: -1.8px;
    margin: 0 0 50px;
    @media (max-width: 900px) {
      font-size: 36px;
      line-height: 44px;
      margin: 0 0 6px;
    }
    @media (max-width: 600px) {
      font-size: 36px;
      line-height: 40px;
      margin: 0 0 24px;
    }
  }
  ul {
    margin: 0 0 50px !important;
    @media (max-width: 900px) {
      margin: 0 0 18px !important;
    }
  }
  p {
    font-size: 24px;
    line-height: 44px;
    letter-spacing: -0.72px;
    font-weight: 400;
    color: #fff;
    @media (max-width: 900px) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 10px;
    }
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .custom_checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 900px) {
      margin-bottom: 9px;
    }
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label {
      position: relative;
      cursor: pointer;
      font-size: 24px;
      @media (max-width: 900px) {
        font-size: 14px;
      }
      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  .custom_checkbox label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 3px;
    border: 0.5px solid #9247ff;
    @media (max-width: 900px) {
      padding: 9px;
      margin-right: 10px;
    }
    @media (max-width: 600px) {
      margin-right: 10px;
    }
  }

  .custom_checkbox input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 14px;
    height: 14px;
    background: transparent;
    border-width: 0 2px 2px 0;
  }

  .custom_checkbox input:checked + label::before {
    background-color: #9247ff;
    border: 0.5px solid #9247ff;
  }
  .textarea-holder {
    border-radius: 12px;
    border: 1px solid #9247ff;
    background: rgba(114, 53, 199, 0.08);
    width: 100%;
    height: 190px;
    padding: 30px;
    font-size: 24px;
    color: #fff;
    margin: 0 0 50px;
    @media (max-width: 900px) {
      font-size: 16px;
      height: 120px;
      margin: 0 0 22px;
    }
  }
  .input-top-holder {
    margin: 0;
    .error-msg {
      color: red;
      padding-left: 5px;
      min-height: 24px;
      display: block;
      font-size: 13px;
    }
  }
  .input-holder {
    border-radius: 12px;
    border: 1px solid #9247ff;
    background: rgba(114, 53, 199, 0.08);
    width: 100%;
    height: 54px;
    padding: 0 20px;
    font-size: 18px;
    color: #fff;

    @media (max-width: 900px) {
      font-size: 16px;
    }
    &:focus {
      outline: 1px solid #9247ff !important;
    }
    &.warning {
      border: 1px solid red !important;
      outline: none !important;
    }
  }
  .custom_btn {
    font-family: "poppins", sans-serif !important;
    font-size: 24px !important;
    background: #9247ff;
    border-radius: 100px;
    color: #fff;
    padding: 15px 60px;
    margin: 0 auto;
    height: 66px;
    border: none;
    margin: 0 0 40px;
    @media (max-width: 900px) {
      font-size: 18px !important;
      margin: 0 0 30px;
      padding: 15px 40px;
      height: 55px;
    }
  }
  .copy_right {
    color: #fff;
    font-size: 22px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 18px;
    }
    a {
      color: #b785ff !important;
      font-style: italic;
      font-weight: 500;
      text-decoration: underline !important;
    }
  }
}
.disabled {
  opacity: 0.5;
}

/* Cross Icon CSS */
.modals-close-cross {
  align-items: center;
  background: #2f2f2f;
  border: none;
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0 !important;
  position: absolute;
  right: 10px;
  top: -10px;
  width: 32px;
  @media (max-width: 900px) {
    width: 28px;
    height: 28px;
  }
  @media (max-width: 600px) {
    top: -12px;
  }
  img {
    max-height: 16px;
    max-width: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    @media (max-width: 600px) {
      max-height: 12px;
      max-width: 12px;
    }
  }
}
.grecaptcha-badge {
  z-index: 9999;
}
