@import "./variables.scss";

.container {
    max-width: 1620px !important;
}

.mobOnly {
    display: none !important;
}

@media (max-width: 1150px) {
    .mobOnly {
        display: inline-block !important;
    }

    .deskOnly {
        display: none !important;
    }
}

.sectionText {
    width: 100%;

    &-title {
        font-weight: 900;
        font-size: clamp(1.5rem, 2vw + 0.75rem, 2.35rem);
        line-height: clamp(36px, 2vw + 16px, 54px);
        color: $white;
        margin-bottom: 40px;
        font-family: $heading-font;
        text-shadow: 0px 0px 16px rgba(245, 133, 12, 0.8);
        text-transform: uppercase;
        &.heavy{ font-family: $title-font!important; }
        
    }

    // & .heavey{ font-family: $title-font!important; }
    &-content {
        width: 100%;

        p {
            font-size: 14px !important;
            line-height: 28px;
            font-weight: 400;
            margin-bottom: 16px;

        }
    }

    .custom-btn {
        font-size: 18px !important;

        @media (max-width: 990px) {
            font-size: 16px !important;
        }
        @media (max-width: 767px) {
            font-size: 14px !important;
        }
    }
}

// .sectionText-title.heavy{ font-family: $title-font!important; }


.sc {
    position: relative;
    background-size: cover;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

ul {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

ul,
li {
    list-style: none;
}

input:focus {
    background: inherit !important;
    box-shadow: none !important;
    outline: none;
}

a {
    color: $white !important;
    text-decoration: none !important;
    cursor: pointer;
}

.fadeInAnimation {
    animation: fade 2s 1s both;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}