button {
    font-size: 14px !important;
    font-family: $secondary-font !important;
}

.custom-btn {
    position: relative;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-family: $secondary-font !important;
    background: none !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.cut-corner-btn {
    padding: 0 !important;

    .cut-corner {
        position: relative;
        height: 100%;
        width: 100%;
        border-right: 1px solid $primary;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
        padding: 10px;
    }

    .cut-corner:after,
    .cut-corner:before {
        position: absolute;
        content: "";
        width: calc(100% + 1px);
        left: 0px;
        z-index: -1;
        background: rgba(2, 3, 5, 0.5);
    }

    .cut-corner:before {
        height: 30%;
        top: 0px;
        border: 1px solid $primary;
        border-width: 1px 0px 0px 2px;
        transform: skew(-45deg);
        transform-origin: right bottom;
    }

    .cut-corner:after {
        height: calc(70% - 0px);
        bottom: 0px;
        right: 0px;
        border: 1px solid $primary;
        border-width: 0px 1px 1px 1px;
    }

    .outer-border {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    @media (min-width: 990px) {
        &:hover {
            .outer-border {
                position: absolute;
                left: -6px;
                right: -6px;
                top: -6px;
                bottom: -6px;
                width: calc(100% + 12px);
                height: calc(100% + 12px);
                border-right: 1px solid $white;
                overflow: hidden;
                opacity: 1;
            }

            .cut-corner:after,
            .cut-corner:before {
                background: $primary;
                border: none;
            }

            .outer-border:after,
            .outer-border:before {
                position: absolute;
                content: "";
                width: calc(100% + 1px);
                left: 0px;
                z-index: -1;
            }

            .outer-border:before {
                height: 30%;
                top: 0px;
                border: 1px solid $white;
                border-width: 1px 0px 0px 2px;
                transform: skew(-45deg);
                transform-origin: right bottom;
            }

            .outer-border:after {
                height: calc(70% - 0px);
                bottom: 0px;
                right: 0px;
                border: 1px solid $white;
                border-width: 0px 1px 1px 1px;
            }
        }
    }
}

.icon-btn {
    width: 40px;
    height: 40px;
    border-radius: 2px !important;
    border: 1.5px solid $primary !important;
    // background: $primary-opacity !important;
    background: rgba(2, 3, 5, 0.5);
    padding: 4px !important;

    .img,
    svg {
        width: 20px;
        height: auto;
    }

    @media (min-width: 990px) {
        &:hover {
            background: $primary !important;
        }
    }
}

.btn-md {
    height: 40px !important;
    line-height: 40px;
}

.btn-lg {
    height: 54px !important;
    line-height: 48px;

    @media (max-width: 767px) {
        height: 48px !important;
    }
}

.line {
    width: 2px;
    height: auto;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
}

.cut-border-button {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: $white;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .cut-corner {
        position: relative;
        height: 100%;
        width: 100%;
        background: url(https://assets-cdn.virtua.com/images/play/jetpack/btn_border.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .15s ease-in-out;
        font-family: $title-font!important;
        &::before {
            content: '';
            position: absolute;
            left: -5px;
            top: -5px;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            background: transparent;
            z-index: -1;
            transition: .75s ease-in-out;
        }
    }

    @media (min-width: 990px) {
        &:hover {
            .cut-corner {
                background: url(https://assets-cdn.virtua.com/images/play/jetpack/hover-btn-bg.svg);
    
                &::before {
                    background: url(https://assets-cdn.virtua.com/images/play/jetpack/hover_white_border.png) no-repeat center center;
                    background-size: 100% 100%;
                }
            }
        }
    }
}